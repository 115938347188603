.main{
    display: flex;
    flex-direction: row;
    // // justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 10px 10%;
    // background: rgb(251,72,255);
    // background: linear-gradient(160deg, rgba(251,72,255,1) 0%, rgba(197,138,255,1) 27%, rgba(122,221,255,1) 100%);
    .side-column{
        width: 20%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }
    .content{
        padding: 0;
        margin: 0;
        width: 80%;
        // display: flex;
        // justify-content: flex-start;
    }
}