@import './config/variables.scss';
@import "~reactjs-popup/dist/index.css";

$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 2% 10%;

$border-color:rgba(110, 90, 167, 0.5);

$title-color: #6E5AA7;

.main{
    flex-wrap: wrap;

    text-decoration: none;

    @media (max-width:1024px) {
        padding: 2% 5%;
    }
    
    @media (max-width:501px) {
        padding-left: 3%;
    }
    
    .product-content{
        display: flex;
        //align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        // margin: 1.5em;
        width: 100%;
        // color: #ffffff;
        // @media (max-width:809px) {
        //     width: 90%;
        // }
        @media (max-width:470px) {
            width: 100%;
            margin: 0;
        }


        .post-render{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // align-items: baseline;
            align-content: flex-start;
            gap: 5px;

            .product-images{
                background-color: $background-color-component;
                padding: $padding-component;
                border-radius: $border-radius-component;
                width: 60%;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                justify-content: center;
                gap:10px;
                // justify-content: space-between;
                .product-show{
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    // border-radius: 30px;
                    // overflow: hidden;
                    .product-preview-container{
                        // height: 100%;
                        width: 100%;
                        height: 500px;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        cursor: zoom-in;
                        overflow: hidden;
                        border-radius: 10px;
                        .show{
                            // width: fit-content;
                            // border: solid 12px #6E5AA7;
                            width: fit-content;
                            height: 100%;
                        }
                        .video-show{
                            width: 90%;
                            border-radius: 10px;
                            // border: solid 12px #6E5AA7;
                            // width: fit-content;
                            height: 100%;
                        }
                    }

                    .preview-point{
                        position: absolute;
                        top:0.5em;
                        right: 0.5em;
                        width: 8em;
                        display: flex;
                        flex-direction: column;
                        // justify-content: flex-end;
                        align-items: flex-end;
                        box-shadow: 2px 2px 5px gray, -2px 0px 5px grey;
                        .point{
                            width: 100%;
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            // gap:5px;
                            font-size: $title-font-size;
                            font-weight: 600;
                            svg{
                                margin-left: 10px;
                                font-size: $title-font-size;
                            }
                        }
                        .seen-point{
                            background-color: white;
                            svg{
                                color: rgb(31, 83, 255);
                            }
                        }
                        .rated-point{
                            background-color: $title-color;
                            width: 100%;
                            padding: 0 5px;
                            color: white;
                            border-radius: 3px;
                            font-size: 1.7em;
                            svg{
                                color: rgb(255, 241, 41);
                            }
                        }
                    }
                }
                .contact-infor{
                    width: 85%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // gap:10px;
                    // justify-content: center;
                    // // border: 2px solid #ff6e3d;
                    // background-color: rgb(231, 231, 231);
                    // padding: 10px 0;
                    // border-radius: 40px;
                    .contact-instuction{
                        // transition: all 0.35ms ease-in;
                        width: 100%;
                        display: flex;
                        align-content: center;
                        // gap:10px;
                        justify-content: center;
                        // border: 2px solid #ff6e3d;
                        background-color: #427ace;
                        padding: 5px 10px;
                        border-radius: 2px;
                        border: solid 2px #427ace;
                        // cursor: pointer;
                        span{
                            font-size: $subtitle-font-size;
                            // font-weight: bold;
                            color: white;
                        }
                        .contact-infor-item{
                            display: flex;
                        }
                    }
                    
                }
                .product-thumb-bar{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    .switch-btn{
                        cursor: pointer;
                        background-color: $background-color-component;
                        border: none;
                        svg{
                            font-size: 3em;
                            &:hover{
                                color: #6E5AA7;
                            }

                            &:active{
                                color: #925aa7;
                            }
                        }
                        
                    }
                    .product-thumb-bar-content{
                        width: 85%;
                        overflow-x: auto;
                        padding: 0 2px;
                        border-left: solid 2px rgba(110, 90, 167, 0.5);
                        border-right: solid 2px rgba(110, 90, 167, 0.5);

                        .product-thumb-container{
                            width: max-content;
                            display: flex;
                            gap: 3px;
                            transition: all 0.35ms ease;
                            .thumb-item{
                                // width: 150px;
                                position: relative;
                                border-radius: 10px;
                                overflow: hidden;
                                height: 80px;
                                display: flex;
                                justify-content: center;
                                .thumb{
                                    cursor: pointer;
                                    max-width: 100%;
                                    height: 100%;

                                }
                                .play-video{
                                    position: absolute;
                                    top: calc(50% - 15px);   
                                    left: calc(50% - 15px);
                                    display: flex;
                                    // width: 150px;
                                    // height: 100%;
                                    // justify-content: center;
                                    // align-items: center;
                                    svg{
                                        font-size: 30px;
                                        opacity: 0.7;
                                        color: white;
                                    }
                                }
                                &:hover{
                                    border: solid 5px #cebcff;
                                        
                                }
                                &:active{
                                    border: solid 5px #6E5AA7;
                                    transform: scale(0.9);
                                }
                            }
    
                            .thumb-item-active{
                                // width: 150px;
                                position: relative;
                                overflow: hidden;
                                height: 80px;
                                border-radius: 10px;
                                border: solid 5px #6E5AA7;
                                .thumb{
                                    max-width: 100%;
                                    height: 100%;
                                }
                                .play-video{
                                    position: absolute;
                                    top: calc(50% - 15px);   
                                    left: calc(50% - 15px);
                                    display: flex;
                                    display: flex;
                                    svg{
                                        font-size: 30px;
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .basic-params-content{
                background-color: $background-color-component;
                padding: $padding-component;
                border-radius: $border-radius-component;

                width: 39%;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                align-content: flex-start;
                .product-name{
                    width: 100%;
                    margin-bottom: 10px;
                    padding-bottom: 20px ;
                    
                    .name{
                        color: #ff6e3d;
                        font-size: 2em;
                        font-weight: bold;
                        // user-select: none;
                    }
                    .sub-name{
                        // font-size: 1em;
                        font-style: italic;
                        font-weight: 400;
                    }
                }
                
                .params-container{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: baseline;
                    width: 100%;
                    padding: 10px 0;
                    gap: 10px;
                    // border-bottom: solid 2px #6E5AA7;
                    border-top: solid 2px #6E5AA7;
                    .title{
                        width: 100%;
                        color: $title-color;
                        p{
                            // font-size: $title-font-size;
                        }
                    }
                    .params-item-box{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        // flex-direction: column;
                        // gap:5px;
                        .basic-params-item{
                            width: 100%;
                            display: flex;
                            border-bottom: solid 1px $border-color;
                            padding: 5px 0;
                            .basic-params-title{
                                width: 50%;
                                h4{
                                    font-size: $content-font-size;
                                    font-weight: 700;
                                }
                            }
                            .basic-params-value{
                                width: 50%;
                                display: flex;
                                flex-wrap: wrap;
                                // justify-content: flex-end;
                                h4{  
                                    width: 100%;
                                    font-size: $content-font-size;
                                    font-weight: 500;

                                }
                            }
                            .ef-size-params-value{
                                width: 50%;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 3px;
                                .option{
                                    padding: 2px 3px;
                                    border: solid 2px #6E5AA7;
                                    width: 45%;
                                    text-align: center;
                                    border-radius: 5px;
                                    color: #6E5AA7;
                                    font-weight: 500;
                                    cursor: pointer;

                                    &:hover{
                                        background: #6E5AA7;
                                        color: white;
                                    }
                                }
                                .option-active{
                                    padding: 2px 3px;
                                    border: solid 2px #6E5AA7;
                                    width: 45%;
                                    text-align: center;
                                    border-radius: 5px;
                                    background-color: #6E5AA7;
                                    color: white;
                                    font-weight: 500;
                                    cursor: none;

                                }
                            }
                            .basic-params-input{
                                width: 50%;

                                input{
                                    padding: 3px 3px;
                                    border: none; /* Remove the default border */
                                    border-bottom: 1px solid $border-color; /* Add bottom border */
                                    outline: none;
                                    width: 100%;
                                    background-color: rgb(247, 247, 247);
                                    font-size: 0.9em;
                                    &:focus{
                                        background-color: #fff;
                                    }
                                }
                            }
                        }
                         
                        .basic-params-item:last-child{
                            border: none;
                        }
                    }

                    .order-btns{
                        width: 100%;
                        display: flex;
                        gap:2%;
                        button{
                            width: 49%;
                            height: 50px;
                            border-radius: 8px;
                            background-color: #6E5AA7;
                            display: flex;
                            border: solid 3px $border-color;
                            a{
                                width: 100%;
                                font-weight: 600;
                                color: white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            align-items: stretch;
                            justify-content: center;
                            cursor: pointer;
                            &:hover{
                                background-color: #f3eeff;
                                a{
                                    color: #6E5AA7;

                                }
                            }
                            &:active{
                                background-color: #6E5AA7;
                                a{
                                    color: white;

                                }
                            }
                        }
                    }
                }
            }

            .order-infor{
                background-color: $background-color-component;
                padding: $padding-component;
                border-radius: $border-radius-component;

                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                .order-infor-title{
                    width: 100%;
                    p{
                        font-size: $title-font-size;
                    }
                }
                // .order-infor-content{
                //     width: 100%;
                //     display: flex;
                //     flex-wrap: wrap;
                //     gap: 10px;
                //     .order-infor-item{
                //         width: 50%;
                //         display: flex;
                //         flex-wrap: wrap;
                //         justify-content: space-between;
                //         background-color: #853fb7;
                //         padding: 10px;
                //         border-radius: 10px;
                //         .avt-counsulter{
                //             width: 22%;
                //             img{
                //                 width: 100%;
                //                 border-radius: 50%;
                //             }
                //         }
                //         .contact-infor{
                //             width: 76%;
                //             .contact-infor-item{
                //                 width: 100%;
                //                 display: flex;
                //                 flex-wrap: wrap;
                //                 .title{
                //                     width: 40%;
                //                     font-size: $content-font-size;
                //                     color: white;
                //                 }
                //                 .value{
                //                     color: white;

                //                     width: 60%;
                //                     font-size: $content-font-size;

                //                 }
                //             }
                //         }
                //     }
                // }
            }

            .product-options-content{
                background-color: $background-color-component;
                padding: $padding-component;
                border-radius: $border-radius-component;

                width: 100%;
                display: flex;
                flex-wrap: wrap;
                
                .title{
                    width: 100%;
                    p{
                        color:$title-color;
                        font-size: $title-font-size;
                        color: $title-color;
                    }
                }
                .product-option-container{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: baseline;
                    // gap:50px;
                    .unit-price-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        justify-content: space-between;
                        margin-top: 20px;
                        gap:30px;
                    }
                }
            }

            .product-introduce{
                background-color: $background-color-component;
                // padding: $padding-component;
                border-radius: $border-radius-component;

                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                overflow: hidden;

                .infor-bar-controller{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    transition: all 0.3ms ease;
                    background-color: rgb(223, 223, 223);
                    .infor-bar-title{
                        cursor: pointer;
                        width: 50%;
                        height: 3em;
                        display: flex;
                        justify-content: center;
                        // background-color: #8d7bb8;
                        align-items: center;
                        font-size: $subtitle-font-size;
                        border-bottom: solid 2px hsla(256, 30%, 50%, 0.6);
                        h3{
                            color: hsla(0, 0%, 23%, 0.671);
                            // transform: to-upper-case($string: "");
                        }
                    }

                    .infor-bar-title-active{
                        width: 50%;
                        height: 3em;
                        display: flex;
                        justify-content: center;
                        background-color: $background-color-component;
                        align-items: center;
                        font-size: $subtitle-font-size;
                        border-radius: 8px 8px 0 0;
                        // background-color: rgb(223, 223, 223);
                        // border-bottom: solid 2px hsla(256, 30%, 50%, 0.6);

                        h3{
                            color: #6E5AA7;
                            // font-weight: 600;
                            // transform: to-upper-case($string: "");
                        }
                    }
                }

                .infor-tab-content{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: $padding-introduce_cpn;

                    .product-introduce-title{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 10px;
                        hr{
                            width: 100px;
                            height: 2px;
                            border: none;
                            background-color: $title-color;
                            border-radius: 10px;
                        }
                        h1{
                            color: $title-color;
                            font-size: calc($title-font-size + 1em);
                            font-weight: 600;
                        }
                    }
                    .description{
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .options-description{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap:20px;
                        .material-des{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            gap: 15px;
                            .material-des-title{
                                width: 100%;
                                h3{
                                    font-size: $title-font-size;
                                    color: $title-color;
                                    // font-weight: 600;
                            
                                }
                            }
                        }
                        .effect-des{
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            .effect-des-title{
                                width: 100%;
                                h3{
                                    font-size: $title-font-size;
                                    color: $title-color;
                                    font-weight: 600;
                            
                                }
                            }
                        }
                    }

                    .notify{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 40px;
                    }

                }

            }

            .print-area{
                background-color: $background-color-component;
                // padding: $padding-component;
                border-radius: $border-radius-component;
                width: 100%;
                padding: $padding-introduce_cpn;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                .print-area-content-title{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 10px;
                    hr{
                        width: 100px;
                        height: 2px;
                        border: none;
                        background-color: $title-color;
                        border-radius: 10px;
                    }
                    h1{
                        color: $title-color;
                        font-size: calc($title-font-size + 1em);
                        font-weight: 600;
                    }
                }
                .print-area-content{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap:10px;
                    img{
                        width: 100%;
                        border-radius: 20px;
                    }
                }
            }

            .questions{
                background-color: $background-color-component;
                // padding: $padding-component;
                border-radius: $border-radius-component;

                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap:30px;
                padding: $padding-introduce_cpn;
                justify-content: center;
                .questions-title{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 10px;
                    hr{
                        width: 100px;
                        height: 2px;
                        border: none;
                        background-color: $title-color;
                        border-radius: 10px;
                    }
                    .title{
                        .main-title{
                            text-align: center;
                            color: $title-color;
                            font-size: calc($title-font-size + 1em);
                            font-weight: 600;
                        }
                        .sub-title{
                            font-style: italic;
                            text-align: center;
                            color: rgba(67, 67, 67, 0.5);
                        }
                    }
                }
                .question-content{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap:40px;
                    .question-item{
                        display: flex;
                        flex-wrap: wrap;
                        gap:10px;
                        .question-text{
                            width: 100%;
                            font-size: $subtitle-font-size;
                            color: $title-color;
                            font-weight: 550;
                        }
                        .answer-text{
                            width: 100%;
                            
                            font-size: $content-font-size;
                        }
                    }
                }
            }

        }
    }
    @include media-query(md){
        .product-content{

            .post-render{
                .product-images{
                    .contact-infor{
                        .instruction{
                            
                        }
                    }
                }
            }
        }
    }
    @include media-query(md){
        .product-content{

            .post-render{
                .product-images{
                    width: 100%;
                    .contact-infor{
                        .contact-btn{
                            // width: 100%;
                        }
                    }
                }
                .basic-params-content{
                    width: 100%;
                }
            }
        }
    }
    @include media-query(xs){
        .product-content{

            .post-render{
                .product-images{
                    width: 100%;
                    .contact-infor{
                        .contact-btn{
                            // width: 100%;
                            p{
                                font-size: 1em;
                            }
                        }
                    }
                }
                .basic-params-content{
                    width: 100%;
                }
                .product-introduce{
                    .infor-bar-controller{
                        .infor-bar-title{
                            h3{
                                font-size:0.9em;
                            }
                        }
                        .infor-bar-title-active{
                            h3{
                                font-size:0.9em;
                            }
                        }
                    }

                    .infor-tab-content{
                        .product-introduce-title{
                            h1{
                                font-size: 1.5em;
                            }
                            hr{
                                width: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}

