@import "../../styles/config/mixins.scss";
@import "../../styles/config/variables.scss";

p{
    margin:0;
  }
  .comment-idex-container{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    
    
    .infor-bar{
      width:100%;
      display:flex;
      align-items:end;
      gap:10px;

      .user-infor{
        width: 50%;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
      /*   align-content: baseline; */
        background-color: white;
        padding:5px;
        border-radius: 10px 10px 0 0;
        gap:10px;
        .user-name {
          p{
            font-size:1em;
            font-weight:600;
            color:rgb(144, 118, 201);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
      
      .rated-point{
        background-color: $title-color;
        padding: 3px 5px;
        color: white;
        border-radius: 3px;
        display: flex;
        align-items: flex-end;
        gap: 5px;
        font-size: $content-font-size;
        b{
          font-size: $subtitle-font-size;
        }
        svg{
          color: rgb(255, 241, 41);
          font-size: 1.6em;
        }
        &::before{
          content: "Đã đánh giá:";
        }
      }
      
      .avatar-idx{
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        overflow: hidden;
        img{
         width:100%;
        }
      }
      

    }
    .cmt-content{
    
      width:100%;
      display:flex;
      flex-wrap:wrap;
      background-color: white;
      padding:5px;  
      border-radius: 0 10px 10px 0;
      
      p{
        font-size: $content-font-size;
        width:100%;
        padding:5px;
        border: solid 1px rgb(144, 118, 201);
        background-color: rgb(144, 118, 201,0.2);
        border-radius: 5px;
      }

    }

    .cmt-time{
      width: 100%;
      color: #7A797A;
      margin-left: auto;
      font-size: 0.8em;
      display: flex;
      justify-content: flex-end;
      // p{
      //   overflow: hidden;
      //   text-overflow:ellipsis;
      //   white-space: nowrap;
      // }
    }

    @include media-query(xs){
      .infor-bar{
        .user-infor{
          width: 12em;
        }
        .rated-point{
          padding-left: 1em !important;
          &::before{
            display: none;
          }
        }
      }
    }
  }
  

  

  
  