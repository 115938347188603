@import './config/variables.scss';
@import './config/mixins.scss';
*{  
  // font-family: $font-family;
  // font-size: $font-size-base;
  text-decoration: none;
}

$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 2% 10%;

$border-color:rgba(110, 90, 167, 0.5);

$title-color: #6E5AA7;
$dropdown-bg-color: #5d5d5d;
  
header {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  /* Header styles */
  .hamberger-Menu{
    display: none;
  }
  .loader-container{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-color-component;
    z-index: 100;
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-color;
    color: $secondary-color;
    height: 2.5rem;
    width: 100%;
    padding: 0 10%;
    .fanpage-list{
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;

      svg{
        font-size: 1em;
      }
    }
    // flex-direction: ;
    .right-infor{
      list-style: none;
      display: flex;
      align-items: center;
      // margin-right: 3rem;
      li {
        width: 10em;
        position: relative;
        // margin: 0 0.7rem;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap:5px;
        p{
          font-weight: 500;
          color: #29ff4f;
        }
        .dropdownacc-menu{
          width: 130%;
          position: absolute;
          top: 0;
          left: -10%;
          z-index: 1000;
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s ease-out;
        }
        &:hover{
          a:nth-child(1){
            opacity: 1;
            transition: opacity 0.2s 0.3s ease-out;

          }
          a:nth-child(2){
            opacity: 1;
            transition: opacity 0.4s 0.3s ease-out;

          }
          a:nth-child(3){
            opacity: 1;
            transition: opacity 0.6s 0.3s ease-out;

          }
          a:nth-child(4){
            opacity: 1;
            transition:  opacity 0.8s 0.3s ease-out;

          }
          .dropdownacc-menu{
            top:100%;
            opacity: 1;
            padding-top: 0.5em;
            pointer-events: all;
            transition: all 0.3s ease-out;
            
          }
        }
        button{
          color: #e7e7e7;
          background-color: transparent;
          border: none;
          border-radius: 5px;
          padding: 0 5px;
        }

        button:hover{
          color: black;
          background-color: white;
        }
        a {
          color: $white-color;
          font-weight: 600;
          border-radius: 5px;
          padding: 0.1em 0.4em;
          cursor: pointer;
          // text-decoration: none;
          
          &:hover {
            
            color: #000;
            background-color: #e7e7e7;
            
          }
        }

        .dropdownacc{
          border-radius: 7px;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 10% 0;
          background-color: $dropdown-bg-color;
          border-top:solid 5px $title-color;
          box-shadow: 2px 2px 10px #555555, -2px -2px 10px #555555;
          a {
            margin: 5%;
            width: 100%;
            color: $white-color;
            font-weight: 600;
            border-radius: 5px;
            padding: 0.1em 0.4em;
            cursor: pointer;
            // text-decoration: none;
            opacity: 0;
            &:hover {
              
              color: #000;
              background-color: #e7e7e7;
            }
          }
        }
      }
    }
  }


  .navbar {
    width: 100%;
    display: flex;
    // padding: 0.7% 0;
    padding-top: 0.7%;
    background-color: #e7e7e7;
    color: $black-color;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .name-n-logo{
      width: 100%;
      display: flex;
      padding: 0 10%;
      justify-content: flex-start;
      flex-wrap: wrap;
      // padding-left: 4rem;
      // width: fit-content;
      .header-logo-container{
        width: 40%;
        .header__logo {
          // margin-bottom: 1rem;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap:10px;
          .store-logo{
            // padding: 0 0.3rem;
                      
            width: 15%;
            img {
              width: 100%;
            }
            
          }
          .store-name{
            width: 85%;
            h1{
              // white-space: nowrap;
              font-size: 2.7em;
              font-weight: 800;
              color: #6E5AA7;
              text-decoration: none;
            }
          }
        }
      }
      .searching-bar{
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        #header-searching-input{
          width: 80%;
          line-height: 1.9em; //3em - (font-size)
          font-size: $subtitle-font-size;
          padding: 0 0.5em;
          border-left: solid 2px $border-color;
          border-top: solid 2px $border-color;
          border-bottom: solid 2px $border-color;
          border-right: none;
          border-radius: 3px 0 0 3px;
          outline:none ;
          &:focus{
            // outline-color: $title-color;
            border-color: $title-color;
          }
        }
        .searching-btn{
          width: 20%;
          height: 2.8em;
          border: solid 2px $title-color;
          border-radius: 0 3px 3px 0;
          background-color: $title-color;
          color: white;
          font-weight: 700;
          cursor: pointer;
          &:hover{
            background-color: white;
            color: $title-color;
            // border-color:
          }
        }
      }

    }
    /* Navigation styles */
    .header-nav {
      width: 100%;
      padding: 1em 10%;
      display: flex;
      justify-content: space-around;
      border-top: solid 1px white;
      // border-bottom: solid 1px white;
      // background-color: white;
      ul {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // font-family:'Courier New', Courier, monospace; 
        li {
          &:first-child{
            justify-content: flex-start;
          }
          &:last-child{
            justify-content: flex-end;
          }
          // display: inline-block;
          //width: 12rem;
          display: flex;
          justify-content: space-around;
          position: relative;
          padding: 0.1em 1.5em;
          // margin: 0 1rem;
          // border: 5px;
          // align-items: center;
          background-color: #ffffff;
          border-radius: 3px;
          cursor: pointer;
          // .icon {
          //   font-size: 1.5rem;            
          // }
          .switchingPage {
            width: 100%;
            display: flex;
            color: $black-color;
            text-decoration: none;
            justify-content: center; 
            flex-wrap: wrap;
            align-items: center;
            .textHeader{
              font-size: 1.2rem;
              font-weight: 700;
              width: fit-content;
              // margin: 0.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
            } 
          }


          &:hover{
            // border-radius: 8px;
            background-color: $title-color;
            .dropdown-products-list{
              opacity: 0.95;
              pointer-events: all;
              transition:.5s ease;
              .product-list{
                margin-top: 0.2em;
                transition:.5s ease;
              }
            }
            .switchingPage{
              .textHeader{
                color: white;

              }
            }
          }
          .dropdown-products-list{
            
            position: absolute;
            top:100%;
            left: -270%;
            z-index: 1000;
            opacity: 0;
            pointer-events: none;
            transition:.5s ease;
            
            .product-list{
              transition:.5s ease;
              backdrop-filter: blur(2px);
              margin-top: -3em;
              width: 70em;
              background-color: hsla(256, 30%, 50%, 1);
              border: solid 2px $border-color;
              display: flex;
              flex-wrap: wrap;
              // border-radius: 7px;
              padding: 1em;
              justify-content: space-around;
              align-items: stretch;
              backdrop-filter: blur(15px);

              .category-box{
                padding: 0.5em;
                width: 18%;
                margin: 1em 0;
                padding-top: 1.5em;
                padding-left: 1.2em;
                display: flex;
                flex-wrap: wrap;
                align-content: baseline;
                border:solid 2px white;
                border-radius: 10px;
                gap: 5px;
                position: relative;
                .title{
                  position: absolute;
                  bottom: 93%;
                  background-color: hsla(256, 30%, 50%);
                  padding: 0 5px;
                  left: 10px;
                  width: max-content;
                  // margin-bottom: 10px;
                  p{
                    color: white;
                    font-size: $subtitle-font-size;
                    font-weight: bold;
                  }
                }
                .products{
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  .product-name{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    color: rgb(237, 237, 237);
                    transition: .3s ease-out;
                    margin-bottom: 5px;
                    hr{
                      border: none;
                      width: 0%;
                      height: 2px;
                      margin: 0;
                      transition: .3s ease-out;
                      background-color: $highlight-title-color;
                    }
                    &:hover{
                      color: $highlight-title-color;
                      transition: .3s ease-in;
                      hr{
                      transition: .3s ease-out;
                      width: 100%;
                      }
                    }
                    // border: solid 1px white;
                  }
                }
              }
            } 
          }
          
          
        }
      hr{
        margin: auto 0.3rem;
        width: 0.2rem;
        height: 1rem; /* increase the height to make it thicker */
        border-radius: 8px; /* add border radius */
        border: none; /* remove the default border */
        background-color: #cacaca;
      }
      }
    }
  }
  .advbar{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;

    background-color: $primary-color2;
    // align-content: center;
    // justify-self: center;
    // text-align: center;
    button{
      margin: 0.4rem 4rem;
      border: none;
      // &:hover{ }
    }
    a{
      text-decoration: none;
      color: $primary-color;
      text-align: center;
    }
  }
  
  @include media-query(lg){
    .navbar{
      .name-n-logo{
        a{
          .header__logo{
            div{
              width: 4rem;
            }
            .store-name{
              h1{
                font-size: 2em !important;
              }
            }
          }
        }
      }
      .header-nav{
        ul{
          // width: 70%;
          justify-content: space-around;
          li{
            .switchingPage{
              .textHeader{
                width: 100%;
                font-size: 0.95em;
              }
            }
            .dropdown-products-list{
              .product-list{
                width: 80vw;
              }
            }
          }
        }
      }
    }
  }

  @include media-query(md){
    .navbar {
      // justify-content: space-between;
      .name-n-logo{
        // width:50%;
        justify-content: space-between;
        .header-logo-container{
          width: 60%;
          .header__logo {
            // margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            gap:5px;
            div{
              padding: 0;
              
              img {
                max-width: 4.2em;
              }
  
            }
            .store-name{

              h1{
                padding-top: 1%;
                font-size: 1.2em !important;
                font-weight: 800;
              }
            }
          }
        }
        .searching-bar{
          width: 40%;
          #header-searching-input{
            width: 60%;
            line-height: 1.5em;
            font-size: 0.9em;
          }
          .searching-btn{
            width: 40%;
            height: 2em;
            font-size: 0.8em;

          }
        }
      }
      /* Navigation styles */
      .header-nav {
        width: 100%;
        justify-content: flex-end;
        ul {

          li {
            width: 12%;
            display: flex;
            justify-content: space-around;
  
            padding: 0.2rem 0; 
            margin: 0;
            .icon {
            }

            .textHeader{
              font-size: 0.7rem !important;
            }
            .dropdown-products-list{
              left: -294%;
              .product-list{
                // width: 90vw;
                .category-box{
                  width: 23%;
                }
              }
            } 
          }
        }
      }
    }
    
  }
  @include media-query(sm){
    .top-bar {
      *{
        font-size: 12px;
      }
      .fanpage-list{
        h5{
          display: none;
        }
      }
      ul {
        margin: 0;
      }
    }
  
    .navbar {
      .name-n-logo{
        // width: 20%;
        .header__logo {
          div{
            padding: 0;
            // gap: 0;
            
            p{
              display: none;
            }
          }
        }
      }
      /* Navigation styles */
      .header-nav {
        // width: 90%;
        .name-n-logo{
          .header-logo-container{
            .header__logo{
              .store-name{
                h1{

                }
              }
            }
          }
        }
        ul {
          width: 100%;
          justify-content: center;
          li {
            width: 23%;
            
            a{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
    
              padding: 0; 
              margin: 0;
              .icon {
                font-size: 1.7rem; 
                width: 100%;           
              }
  
              .textHeader{
                width: 100%;
                font-size: .6em;
              } 
            }
            .dropdown-products-list{
              left: -285%;
              top:85%;
              .product-list{
                width: 95vw;
                padding: 0.5em;
                justify-content: space-between;
                .category-box{
                  .title{
                    bottom: 95%;
                    p{
                      font-size: 12px;
                    }
                  }
                  .product-name{
                    font-size: .6em;
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
  @include media-query(xs){
    .hamberger-Menu{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      button{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        border: none;
        background: none;
        transition: all 0.3s ease;
      }
    }
    .top-bar{
      padding: 0 10px;
    }
    .navbar {
      .name-n-logo{
        padding: 0 10px;
        justify-content: space-between;
        // align-items: baseline;
        // gap: 10px;
        .header-logo-container{
          width: 80%;
          .header__logo {
            justify-content: center;
            .store-logo{
              // width: 80%;
              width: 4rem;
              padding: 0;
              
            }
            .store-name{

              h1{
                font-size: 2em !important;
                // text-align: end;
              }
            }
          }
        }
        
        .searching-bar{
          margin: 10px 0;
          width: 100%;
        }
      }
      .header-nav {
        position: fixed;
        left: 0;
        top:0;
        background-color: rgb(231, 231, 231);
        width: 30%;
        height: 100%;
        z-index: 1000;
        box-shadow: 2px 2px 5px gray;
        padding: 2%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        ul {
          align-items: center;
          // justify-content: flex-start;
          flex-direction: column;
          hr{
            margin: 0.5em 0;
            height: 0.2em;
            width: 80%;
          }
          li {
            width: 100%;
            position:inherit;
            .switchingPage{
              .textHeader{
                font-size: .9em !important;
              }
            }
            .dropdown-products-list{
              left: 100%;
              top:0;
              height: 100%;
              overflow: auto;
              background-color: rgb(231, 231, 231);
              padding: 5px;
              // align-items: center;
              .product-list{
                width: 65vw;
                // align-self: center;
                .category-box{
                  width: 100%;
                  .title{
                    bottom: 95%;
                    p{
                      font-size: 1.1em;
                    }
                  }
                  .product-name{
                    font-size: 1em;
                  }
                }
              }
            } 
          }
        }
      }
    } 
  }
}


