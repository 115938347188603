@import "../../styles/config/mixins.scss";

.price-table{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:10px;

    // border-radius: 5px;
    .size-dont-contain-price-table{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        span{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:8px;
            p{
                &::after{
                    content: ", ";
                }
                &:nth-last-child(2){
                    &::after{
                        content: "";
                    }
                }
                &:last-child{
                    &::before{
                        content: "hoặc ";
                    }

                    &::after{
                        content: "";
                    }
                }
            } 
        }
    }
    table{
        width: 100%;
        border-collapse: collapse;
        font-size: $content-font-size;
        thead{
            background-color: $title-color;
            color: white;
            // font-style:normal;
            // border-color: $border-color;
        }
        th,td{
            // border: solid 1px rgb(53, 53, 53);
            
            padding: 3px 5px;
            p{
                display: flex;
                justify-content: center;
                gap: 10px;
            }
        }

        th{
            border: solid 1.5px rgb(168 136 255);
            
        }
        td{
            border: solid 1px rgb(53, 53, 53);
        }

        tbody{
            tr {
                td{
                    text-align: center;
                }
                &:nth-child(odd){
                    background-color: $background-color-btn;
                }   
                &:nth-child(even){

                }
            }
        }
    }

}