@import './config/variables.scss';


.footer {
  // position:relative;
  // position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1.5em 0em 10em 0;
  // padding-bottom: 2em;
  width: 100%;
  
  
  .footer__logo {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div{
      padding: 0 .5rem;
      img {
        max-width: 100px;
      }
      h1{
        font-size: 2rem;
        color: #6E5AA7;
      }
    }
    
  }

  hr{
    width: 80%;
    // color: $white-color;
    border-radius: 12px;
    margin-top:1.2em; 
  }

  .infoNlink {

    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: flex-start;
    width: 80%;
    flex-wrap: wrap;
    .liinfolink {
      display: flex;
      flex-direction: column ;
      width: 30%;
      h3 {
        margin-top: 1em;
        font-size: 1.1rem;
        // color: $secondary-color;
      }
      
      ul {
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        list-style-type: none;
        margin-top: 0.5em;
        gap:12px;
        justify-content: flex-start;
        li {

          display: flex;
          width: 100%;
          // color: $secondary-color;
          align-items: center;
          font-size: 1rem;
          gap:5px;
          .service-logo{
            width: 100%;
            overflow: hidden;
            // border-radius: 5px;
            img{
              width: 100%;
            }
          }
          a {
            display: flex;
            align-items: center;
            width: 100%;
            gap:5px;
            // color: $secondary-color;
            text-decoration: none;  
            color: rgb(31, 31, 31);  
            svg{
              width: 2em;
              font-size: 1rem;
            }
            p{
              font-size: 1rem;
              color: rgb(31, 31, 31);  
              font-weight: 500;
              
            }       
            &:hover {
              // color: $tertiary-color;
            }
          }
        }
        .icon{
          font-size: 1.2rem;
        }
      }
      .contactIcon{
        flex-direction: row;
      }     
      p {
        font-size: .6rem;
        color: $secondary-color;
      }
    }
  }
  @include media-query(lg){

  }
  @include media-query(md){
    .infoNlink {
      .liinfolink{
        h3{

        }
        ul{
          li{
            font-size: .8em;
            a{
              svg{
                font-size: .8em;

              }
              p{
                font-size: .8em;

              }
            }
          }
        }
      }
      div{
        width: 40%;
      }
      
    }
  }

  @include media-query(sm){
    .infoNlink {
      width: 90%;
      flex-wrap: wrap;
      justify-content: space-between;
      .liinfolink{
        h3{

          font-size: .9em;
        }
        ul{
          li{
            font-size: .8em;
            width: fit-content;
            a{
              width: fit-content;
              p{
                display: none;
              }
            }
          }
        }
      }
      div{
        width: 40%;
      }
      
    }
  }

  @include media-query(xs){
    .footer__logo{
      div{
        img{
          max-width: 50px;
        }
        h1{
          font-size: 1em;
        }
      }
    }
    .infoNlink {
      width: 80%;
      .liinfolink{
        width: 43%;
        h3{

          font-size: .9em;
        }
        ul{
          li{
            a{
              p{
              }
            }
          }
        }
      }
      div{
      }
      
    }
  }
}