$padding: 2% 20% 8% 20%;
$background-color-component: rgb(247, 247, 247);
$border-color:rgba(110, 90, 167, 0.5);
$title-color: #6E5AA7;

$title-font-size: 1.4em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

.cart-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap ;
    gap: 20px;
    .cart-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        background-color: $background-color-component;
        border-radius: 8px;
        gap:30px;
        .cart-title{
            width: 100%;
            padding-bottom: 10px;
            border-bottom: solid 1px $border-color;
            h2{
                color:$title-color
            }
        }
        .cart-list{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:20px;
            label{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                input{
                    width: 2%;
                }
                .product-in-cart-infor{
                    width: 97%;
                }
            }
        }
    
        .cart-btns{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 10px;
            *{
                cursor: pointer;
            }
    
            .order{
                width: 40%;
                height: 2em;
                display: flex;
                align-items: stretch;
                border-radius: 10px;
                border: solid 2px $border-color;
                background-color: #6E5AA7;
    
                align-items: center;
                justify-content: center;
                font-size: $subtitle-font-size;
                font-weight: 600;
                color: white;
    
    
                &:hover{
                    background-color: #e2d7ff;
                    color: $title-color;
                }
            }
            .view-products{
                width: 40%;
                height: 2em;
                display: flex;
                align-items: stretch;
                border-radius: 10px;
                border: solid 2px $border-color;
                background-color: #6E5AA7;
    
                align-items: center;
                justify-content: center;
                font-size: $subtitle-font-size;
                font-weight: 600;
                a{
                    width: 100%;
                    color: white;
                }
    
    
                &:hover{
                    background-color: #e2d7ff;
                    a{
                        color: $title-color;
    
                    }
                }
            }
        }
    }
}