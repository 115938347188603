/* Import new font*/

@font-face {
    font-family: 'Dosis';
    src: url("../src/components/styles/config/fonts/Acumin-Variable-Concept.ttf/Acumin\ Variable\ Concept.ttf")format('truetype');
    /* src: url("../src/components/styles/config/fonts/font-utm-taimienphi.vn/UTM\ ViceroyJF.ttf")format('truetype'); */
    
    /* src: url("../src/components/styles/config/fonts/HelveticaNeue-Thin.otf")format('truetype'); */

  }
  
  html{
    margin: 0;
    height: 100%;
    width: 100%;
    max-height: fit-content;
    scroll-behavior: smooth; 
  
  }
  body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Dosis', 
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  /* scrollbar */
  
  ::-webkit-scrollbar{
    height: 7px;
    width: 7px;
  }
  
  ::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb{
    background-color: rgb(213, 213, 213);
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background-color: #3a0560;
  }