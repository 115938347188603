$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding: 2% 20%;
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 2% 10%;

$border-color:rgba(110, 90, 167, 0.5);

$title-color: #6E5AA7;

.password{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    .title{
        p{
            font-size: $title-font-size;
            font-weight: 600;
            color: $title-color;
        }
    }
    .password-notice{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: $padding-introduce_cpn;
        background-color: rgb(255, 251, 196);
        font-weight: 600;
    }
    .change-password-form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap:20px;
        .pass-input-box{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            label{
                width: 30%;
            }
            input{
                width: 70%;
                background-color: $background-color-component;
                padding: 8px;
                font-size: $content-font-size;
                border: none; /* Remove the default border */
                border-bottom: 1px solid $border-color; /* Add bottom border */
                outline: none; /* Remove the outline when the input is focused */
                &:focus{
                   background-color: #fff;
                }
            }
        }

        .bottom-bar{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .alert-box{
                width: 100%;
                color: rgb(223, 44, 44);
            }
            .update-n-forget-pass-btn{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                gap:50px;
                button{
                    width: 30%;
                    padding: 0.3em 0;
                    border: solid 2px $title-color;
                    border-radius: 10px;
                    // padding: $padding-component;
                    background-color:#dacefa;
                    font-weight: 700;
                    cursor: pointer;
                    &:hover{
                        background-color: $title-color;
                        color: white;
                    }
                }
                .forget-pass-btn:hover{
                    text-decoration: underline;
                }
            }
        }

    }
}