@import "../../styles/config/mixins.scss";
@import "../../styles/config/variables.scss";
.relation-product{
    background-color: $background-color-component;
    // padding: $padding-component;
    border-radius: $border-radius-component;

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: $padding-introduce_cpn;
    justify-content: center;
    gap: 40px;
    .relation-products-title{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        hr{
            width: 50px;
            height: 2px;
            border: none;
            background-color: $title-color;
            border-radius: 10px;
        }
        .title{
            .main-title{
                text-align: center;
                color: $title-color;
                font-size: calc($title-font-size + 1em);
                font-weight: 600;
            }
            .sub-title{
                font-style: italic;
                color: rgba(67, 67, 67, 0.5);

                text-align: center;
            }
        }
    }

    .relation-products-list{
        width: 100%;
        // display: flex;
        // flex-direction: row;
        
        // align-items: baseline;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap:1em;
        gap: 40px;
        .row-item{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;
            

        }
        
    }

    @include media-query(lg){

    }
    
    @include media-query(md){

    }
    
    @include media-query(sm){

    }
    
    @include media-query(xs){
        .relation-products-title{
            hr{
                width: 1em;
            }
            .title{
                .main-title{
                    font-size: 1.5em;
                }

                .sub-title{
                    font-size: 0.8em;
                }
            }
        }
        .relation-products-list{
            grid-template-columns: repeat(1, minmax(0, 1fr));
            
        }
    }

}