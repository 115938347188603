@import '../../styles/config/mixins.scss';
@import '../../styles//config/variables.scss';


.comment-container{
 
    width: 100%;
    display:flex;
    justify-content:center;
    background-color: $background-color-component;
    padding: $padding-introduce_cpn;
    border-radius: 5px;
    flex-wrap:wrap;
    gap: 20px;
    .comment-content-title{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      hr{
          width: 90px;
          height: 2px;
          border: none;
          background-color: $title-color;
          border-radius: 10px;
      }
      h1{
          color: $title-color;
          font-size: calc($title-font-size + 1em);
          font-weight: 600;
      }
  }
    .comment-content{
      width: 100%;
      display:flex;
      justify-content:center;
      flex-wrap:wrap;
      gap: 10px;
      .write-comment-area{
        width: 100%;
        display:flex;
        justify-content:center;
        .write-comment-container{
          width: 100%;
          display:flex;
          justify-content:center;
          align-items:flex-start;
          gap: 5px;
          .user-avatar{
            width: 3em;
            height:3em;
            border-radius:50%;
            overflow:hidden;
            background-color: #9076C9;
            img{
              width:100%;
            }
          }
          .input-box{
            width: 85%;
            display:flex;
            flex-wrap:wrap;
            align-items:stretch;
            padding: 5px;
            justify-content: space-between;
            border-radius: 5px;
            background-color: white;
            height: auto;
            line-height: 20px;
            .comment-input{
              width: 60%;
              height:auto;
              outline: none !important;
              border:none;
              border-bottom: solid 1px white;
            /*   border-radius: 3px; */
              padding: 5px 10px;
              transition: all 0.2s ease-out;
              line-height: $subtitle-font-size;
              font-size: $subtitle-font-size;
              &:focus{
                background-color:rgb(144, 118, 201,0.2);
                outline: none !important;
                border:none;
                border-bottom: solid 1px rgb(171, 105, 211);
              }
            }
            
            .rating-box{
              width: 40%;
              display: flex;
              flex-wrap: wrap;
              padding: 0 1em;
              // justify-content: flex-end;
              // height: 100%;
              // align-content: space-between;
              .rating-title{
                width: 100%;
              }
              .rating-container{
                width: 100%;
                display: flex;
                gap:1em;
                .start-rating{
                  border: none;
                  background: none;
                  cursor: pointer;
                  padding: 3px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  font-size: $content-font-size;

                  svg{
                    font-size: 1.7em;
                    color: #d0c5e7;
                  }
                  transition: all 0.3s ease;
                  &:hover{
                    background-color: $title-color;
                    box-shadow: 0px 0px 5px rgb(107, 107, 107);
                    border-radius: 3px;
                    color: rgb(255, 241, 41);
                    
                    svg{
                      color: rgb(255, 241, 41);
                    }
                  }
                }

                #one-start{

                }

                #two-start{
                  // background-color: yellow;

                }

                #three-start{

                }

                #four-start{

                }

                #five-start{

                }
              }
            }

          }
          .submit-cmt-btn {

            button{
              width:3.5em;
              height:2.5em;  
              display:flex;
              justify-content:center;
              align-items: center;
              border: none;
              padding:5px;
              background-color:white;
              border-radius:5px;
              font-size: $content-font-size;
              font-weight: 600;
              cursor: pointer;
              &:hover{
                background-color:#9076C9;
                color: white;
              }
              &:active{
                background-color: white;

              }
            }
          }
        }
      }
      .display-comment-area{
        width: 100%;
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        gap:30px;
        padding:20px;
        border: dashed 2px white;
        border-radius:10px;
        background-color:rgb(231, 231, 231);
      /*   box-shadow:inset 0 0 10px 0.5px hsl(277.36, 32%, 66%); */
        .title{
          width:100%;
          display: flex;
          justify-content:center;
          align-items:center;
          gap:10px;
          hr{
            margin:0;
            width: 2em;
            height: 0.1em;
            background-color: hsl(277.36, 32%, 66%);
            border: none;
          }
          h3{
            // font-size: 17px;
      
          }
        }

        .other-comments-wrapper{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap:20px;
        }
        .view-more-comments-btn{
          width: 100%;
          display: flex;
          justify-content: center;
          button{
            // background: none;
            border: none;
            box-shadow: 0px 0px 5px gray;
            padding: 3px 5px;
            color:blue;
            cursor: pointer;
            font-size: $content-font-size;
            transition: all 0.3s ease;

            &:hover{
              padding: 3px 10px;
              transition: all 0.3s ease;
            }
            &:active{
              padding: 3px 5px;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }

    @include media-query(lg){
      .comment-content{
        .write-comment-area{
          .write-comment-container{
            .input-box{
              .comment-input{
                width: 100%;
              }
              .rating-box{
                width: 100%;
                align-content: center;
                .rating-title{
                  width: 7em;
                }

                .rating-container{
                  width: 20em;
                }
              }
            }
          }
        }
      }
    }
    @include media-query(md){
      .comment-content{
        .write-comment-area{
          .write-comment-container{
            .input-box{
              width: 72%;
              
            }
          }
        }
      }
    }
    @include media-query(sm){

    }
    @include media-query(xs){
      .comment-content-title{
        h1{
          font-size: 1.5em;
        }
        hr{
          width: 1.5em;
        }
      }
      .comment-content{
        .write-comment-area{
          .write-comment-container{
            flex-wrap: wrap;
            // .user-avatar{
            //   width: 4em;
            //   height: 4em;
            // }
            .input-box{
              width: 80%;
              .comment-input{
                // width: 100%;
              }
              .rating-box{
                // width: 100%;
                // align-content: center;
                padding: 0 0.5em ;
                .rating-title{
                  width: 100%;
                  font-size: 0.8em;
                }

                .rating-container{
                  // width: 17em;
                  gap:0.7em;
                  .start-rating{
                    font-size: 0.7em;
                  }
                }
              }
            }

            .submit-cmt-btn{
              width: 100%;
              button{
                width: 100%;
              }
            }
          }
        }
      }
    }

  }

