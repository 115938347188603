@import '../../styles/config/variables.scss';
@import '../../styles/config/mixins.scss';

.selection-bar-container{
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 2em;
    .selected-value{
      background-color: white;
      // color: white;
      text-align: center;
      // border: solid 1px black;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 0.5em;
    }
    .option-list-dropdown{
      width: 100%;
      position: absolute;
      top: 80%;
      left: 0;
      opacity: 0;
      display: flex;
      z-index: 100;
      pointer-events: none;
      background-color: $background-color-component;
      box-shadow: 2px 1px 5px $dark-color, -2px 1px 5px $dark-color;
      transition: all 0.3s ease;
      .option-list-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        a{
          line-height: 1.2em;
          padding: 0 0.5em;
          width: 100%;
          color: black;
          &:hover{
            background-color: rgb(231, 231, 231)
          }
        }
      }
    }
    &:hover{
      .option-list-dropdown{
        opacity: 1;
        top:100%;
        pointer-events: all;
        transition: all 0.3s ease;

      }
    }
  }