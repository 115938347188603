/* Import variables and mixins */
@import './config/variables.scss';
@import './config/mixins.scss';

/* Header styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: overlay;
  color: $dark-color;
  background-color: rgb(231, 231, 231);
  // background-image: url(../../../public/img/background/background2.jpg);
  background-size: 100% 1920px;
  // background-repeat: no-repeat;
}

.main-content{
  margin: 0 10%;
  padding: 0.5%;
  width: 80%;
  border-radius: 8px;
  // @media (max-width:510px) {
  //   padding: 0;
  //   width: 100%;
  //   // margin: 1% 5%;
  //   margin: 0;
  // }
  .product-list-container{
    margin-top: 1em;
    margin-bottom: 5em;
    background-color: #ffffff;
    padding: 5% 10%;
    border-radius: 8px;
    .product-list-title{
        
      .start-your-design{
        width: fit-content;
        padding: 0.5em;
        border-radius: 8px;
        background-color: $title-color;
        display: flex;
        align-items: center;
        font-size: 1.5em;
        font-weight: 700;
        box-shadow: 0.1em 0.1em 0.2em #66308d;
        margin-bottom: 1em;
        color: white;
        .design-icon{
          margin-left: 0.3em;
          font-size: 1.2em;
        }
  
      }
  
      .please-chose-product-type{
        // color: white;
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      .product-filter{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgb(231, 231, 231);
        height: 3em;
        padding: 0 1em;
        align-items: center;
        .filter{
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          .filter-title{
            width: 35%;
            height: 1.5em;
            font-weight: 400;
            // background-color: $title-color;
          }
          .selection-filter{
            width: 60%;
          }
        }
      }
  
    }

    .product-list-show{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include media-query(lg){
    .product-list-container{
      .product-list-title{
        .product-filter{
          .filter{
            width: 48%;
          }
        }
      }
    }
  }
  @include media-query(md){

  }
  @include media-query(sm){
    .product-list-container{

      padding: 2em 1em 3em 1em;    
      .product-list-title{
        .start-your-design{
          width: 100%;
          // text-align: center;
          justify-content: center;
          border-radius: 0;
        }
      }
    }
  }
  @include media-query(xs){
    padding: 0;
    width: 100%;
    // margin: 1% 5%;
    margin: 0;
    .product-list-container{
      border-radius: 0;
      .product-list-title{
        .start-your-design{
          font-size: 18px;
          justify-content: center;
          // border-radius: 0;
        }
        .please-chose-product-type{
          font-size: 15px;
          font-style: italic;
        }
        .product-filter{
          height: 6em;
          .filter{
            width: 100%;
          }
        }
      }
    }
  }
}

