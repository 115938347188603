$padding: 2% 20%;
$background-color-component: rgb(247, 247, 247);
$border-color:rgba(110, 90, 167, 0.5);
$title-color: #6E5AA7;

$title-font-size: 1.4em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

.order-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    .order-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        background-color: $background-color-component;
        border-radius: 8px;
        gap:30px;
        .order-title{
            width: 100%;
            padding-bottom: 10px;
            border-bottom: solid 1px $border-color;
            h2{
                color:$title-color
            }
        }
        .order-list{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .order-overview{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap:5px;
                .infor{
                    display: flex;
                    gap: 5px;
                    // justify-content: center;
                    align-items: flex-end;
                    .key{
                        font-size: $subtitle-font-size;
                    }
                }
                .order-code{
                    width: 100%;
                    .key{
                        font-weight: 600;
                        color: $title-color;
                    }
                    .value{
                        font-size: $subtitle-font-size;
                        font-weight: 600;
                        color: $title-color;
                    }
                }
            }
        
            .order-item-list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap:10px;
                .title{
                    display: flex;
                    width: 100%;
                    p{
                        font-size: $subtitle-font-size;
                        font-weight: 600;
                        color: $title-color;
                    }
                }
            }
        }
    
        .payment-instructor{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:20px;
            .title{
                font-size: $title-font-size;
                font-weight: 600;
                color: $title-color;
            }
            .payment-instructor-container{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap:10px;
            }
    
            .note{
                width: 100%;
                padding: 10px;
                border: dashed 2px $border-color;
                border-radius: 10px;
                background-color: rgba(240, 234, 255, 0.785);
            }
        }
    }
}