@import '../../styles/config/mixins.scss';
@import '../../styles/config/variables.scss';

.trending-list-content{
    width: 100%;
    background-color: #ffffff;
    padding: 5% 10%;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap:30px;
    .trending-list-title{
      // margin: 1em 0em;
      border-radius: 8px;
      padding: 0.5em;
      width: fit-content;
      background-color: #ff7722;
      font-size: calc(20px + 0.390625vw);
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
    
      .trending-heart-icon{
        margin: 0px 5px;
        color: #ffffff;
        font-size: 1.3em;
      }
    
      .high-light{
        color: #ff6320;
        padding: 8px;
        margin: 0px 5px;
        border-radius: 8px;
        background-color: #ffffff;
        font-size: calc(22px + 0.390625vw);
      }
    
    }
    .trending-list-container{
      overflow-x: auto;
      // border-radius: 1em;
      height: 23em;
      display: flex;
      align-items: center;
      .trending-list{
        width: max-content;
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        .trending-item-box{
          color: #EEEEEE;
          // display: inline-block;
          width: 12em;
          box-shadow: 2px 2px 5px gray;
          border-radius: 8px;
          padding-bottom: 1em;
          margin: 5px 10px;
          background-color: $title-color;
          //background-image: url("../../../public/img/background2.jpg");
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover{
            transform: scale(1.1);
            transition: all 0.3s ease;
            // margin: 0 1em;
          }
          .sample-pic-with-rank{
            width: 100%;
            position: relative;
            height: 12em;
            display: flex;
            justify-content: center;
            align-items: center;
            .sample-image{
              border-radius: 8px;
              width: 100%;
              // height: 12em;
            }
            .rank{
              position: absolute;
              top: 5%;
              right: 6%;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              box-shadow: 0 5px 9px 0 black;
              font-size: 25px;
              font-weight: 900;
              background-color: #6a0dad;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .sample-infor{
            margin-top: 5px;
            border-radius: 5px;
            .sample-name{
              font-size: calc(12px + 0.390625vw);
              font-weight: bold;
              text-align: center;
              
              height: 1.5em;

              // &:hover{
              //   transform: scale(1.2);
              // }
            }
            
            .options{
              margin-top: 2px;
              padding: 2% 3%;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              li{
                width: 100%;
                list-style-type: none;
                text-align: center;
                font-size: 18px;
                // font-weight: bold;
                font-style: italic;
                height: 1.2em;

              }
            }
          }
        }
      }
    }
    
    //////// RESPONSIVE /////////////////////////////////////////////////
    @include media-query(lg){

    }
    @include media-query(md){

    }  
    @include media-query(sm){
      border-radius: 0;
      padding: 2em 1em 3em 1em;
      gap:0px;
      // background: white;
      .trending-list-title{
        width: 100%;
        border-radius: 0;
        color: white;
      }
      .trending-list-container{
        .trending-list{
          .trending-item-box{
            width: 9em;
            // background: rgb(226, 226, 226);
            .sample-pic-with-rank{
              height: 9em;
              .rank{
                font-size: 15px;
              }
            }
          }
        }
      }
    }  
    @include media-query(xs){

    }

  }

  //// @keyframe ////
.skeleton{
  background-image: linear-gradient(
      90deg,
      #ccc 0px,
      rgb(229 229 229 / 90%) 40px,
      #ccc 80px
  );
  background-size: 300%;
  background-position: 100% 0;
  border-radius: inherit;
  animation: shimmer 2s infinite;
  // border: solid 1px gray;
}
@keyframes shimmer {
  to {
    background-position: -100% 0;
  }
}