$padding: 2% 20%;
$background-color-component: rgb(247, 247, 247);
$border-color:rgba(110, 90, 167, 0.5);
$title-color: #6E5AA7;

$title-font-size: 1.4em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

$btn-height:40px;

.main{
    // width: 100%;
    // display: flex;
    // justify-content: center;
    .order-confirmation{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        background-color: $background-color-component;
        border-radius: 8px;
        gap:30px;
        .title-container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:50px;
            padding-bottom: 10px;
            border-bottom: solid 2px $border-color;
            .main-title{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .title{
                    text-align: center;
                    width: 100%;
                    font-size: 2.5em;
                    font-weight: 700;
                    color: $title-color;
                }
                .sub-title{
                    font-style: italic;
                    color: rgba(0, 0, 0, 0.5);
                }
            }

        }

        .order-list{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:20px;
            .order-list-title{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .title{
                    width: 100%;
                    font-size: $title-font-size;
                    font-weight: 650;
                    color: $title-color;
                }
            }
            .order-list-container{
                width: 100%;
                display: flex;
                flex-wrap: wrap;    
                gap:10px;
            }
        }

        .get-input-container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:20px;
            .user-infor-title{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .title{
                    width: 100%;
                    font-size: $title-font-size;
                    font-weight: 650;
                    color: $title-color;
                }
                .sub-title{
                    font-style: italic;
                }
            }
        }

        .contact-infor{
            .product-name{
                font-size: 2em;
                font-weight: 600;
                color: $title-color;
            }
            .sub-title{
                padding-left: 10px;
                &::before{
                    content: '- ';
                }
            }
        }
        .btns{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center; 
            gap: 10px; 
            button{
                cursor: pointer;
                font-size: $subtitle-font-size;
                font-weight: 600;

                display: flex;                
                // padding: 10px 15px;
                border-radius: 8px;
                border: solid 1px #6E5AA7;
                background-color: #dcd0fa;
                justify-content: center;
                align-items: center;

                &:hover{
                    color: white;                    
                    background-color: #6E5AA7;
                }
            } 

            .order{
                width: 120px;
                height: $btn-height;
            }

            .cancle{
                width: 100px;
                height: $btn-height;
                border-color: #ff8d80;
                background-color: #ffc1ba;
                &:hover{
                    background-color: #ff4b42;
                }
            }
        }
    }
}