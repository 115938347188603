@import '../../styles/config/mixins.scss';

.slideshow{
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    //max-width: 500px;  
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slideshowDots {
    position: absolute;
    bottom: 10%;
    //transform: translate(100%)
  }
  .slideshowDot {
    display: inline-block;
    height: 10px;
    width: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
    @media (max-width:800px) {
      height: 5px;
      width: 10px;
    }
  }
  
  .slideshowDot.active {
    height: 12px;
    width: 22px;
    background-color: #6a0dad;
    @media (max-width:800px) {
      height: 7px;
      width: 12px;
    }
  }
  
  .slide{
    display: inline-block;
  
    // height: 400px;
    width: 100%;
    cursor:pointer;

    img{
      width: 100%;
    }
    // @media (max-width:1300px) {
    //   height: 346px;
    // }
    // @media (max-width:1100px) {
    //   height: 293px;
    // }
    // @media (max-width:800px) {
    //   height: 213px;
    // }
  
    // @media (max-width:600px) {
    //   height: 160px;
    // }
  
    // @media (max-width:400px) {
    //   height: 106px;
    // }
    // border-radius: 8px;
  }

   //// @keyframe ////
.skeleton{
  // height: 400px;
  background-image: linear-gradient(
      90deg,
      #ccc 0px,
      rgb(229 229 229 / 90%) 40px,
      #ccc 80px
  );
  background-size: 300%;
  background-position: 100% 0;
  border-radius: inherit;
  animation: shimmer 2s infinite;
  // border: solid 1px gray;
}
@keyframes shimmer {
  to {
    background-position: -100% 0;
  }
}