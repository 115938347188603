@import './config/variables.scss';

// // MOBILE VERSION
// .page {
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 0.75em;

//   // TABLET OPTIMIZATION
//   @media only screen and (min-device-width: 37em) {
//     font-size: 1em;
//   }

//   // DESKTOP OPTIMIZATION
//   @media only screen and (min-device-width: 64em) {
//     font-size: 1.25em;
//   }
// }

.main{

  .main-register {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: $padding;
    background-color: $background-color-component;
    border-radius: 8px;
    gap:30px;
    @media (max-width:1025px) {
      width: 90%;
      margin: 0 5%;
  
    }
    h1{
      width: 100%;
      font-size: calc(22px + 0.390625vw);
      font-weight: bolder;
      margin-bottom: 1em;
      //font-style: italic;
      text-align: center;
      //font-style: italic;
      color: $title-color;
    }
    .register-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap:30px;
    }
    label{
      width: 100%;
      // width: 100%;
      font-weight: 600;
      font-size: $subtitle-font-size;
      color: $title-color;
      
      .input-error{
        color: red;
  
      }
    }
   
  
    .agree-policies{
      
      display: flex;
      flex-direction: row;
      align-items: center;
      input{
        width: fit-content;
        margin-right: 1%;
      }
      label{
        a{
          font-weight: bold;
          font-size: 1.1em;
          
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .password-input{
      display: flex;
      flex-wrap: wrap;
      gap:10px;
      .password-notice{
        padding: 1%;
        font-weight: 600;
        margin-bottom: 1%;
        background-color: #ECE7B8;
      }
      .alert{
        color: red;
      }
    }
    input {
      background-color: $background-color-component;
      width: 100%;
      padding: 8px;
      font-size: $content-font-size;
      border: none; /* Remove the default border */
      border-bottom: 1px solid $border-color; /* Add bottom border */
      outline: none; /* Remove the outline when the input is focused */
      &:focus{
          background-color: #fff;
      }
      
    }
    
    .login-btn {
      width: 100%;
      height: 3em;
      margin: 1em 0 2em 0;
      // background-color: $primary-color2;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s ease;
      border-radius: 0.25em;
      font-weight: bold;
      font-size: large;
      cursor: pointer;
      border: solid 2px $border-color;
      background-color: $title-color;
      color: white;
      &:hover {
        transform: translateY(-5px);
        color: $title-color;
        background-color: white;
      }
    }
  
  }
}

