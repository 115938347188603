@import '../../styles/config/variables.scss';

.product-item-wrapper{
    border-radius: 8px;
    // margin: 0.5%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    .product-avt{
        width: 100%;
        aspect-ratio: 1/1;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        // flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgb(231, 231, 231);
        .product-img{
            width: 100%;
            text-align: center;
            aspect-ratio: 1/1;
        }
        .hover-gif{
            text-align: center;
            width: 100%;
            // height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: .5s ease;
            aspect-ratio: 1/1;
            &:hover{
                opacity: 1;
            }
        }
    }

    .product-infor{
        // padding: 2% 5%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2px;
        .product-name{
            width: 100%;
            font-size: 1.2em;
            font-weight: 700;
            text-align: center;
            border-radius: 9999px;
            min-height: 30px;
        }
        .product-detail{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap:5px;
            .unit-price, .unit-price-loading{
                min-height: 20px;
                width: 100%;
                font-size: 1em;
                display: flex;
                justify-content: center;
                gap:5px;
                color: rgba(0, 0, 0, 0.5);
                font-style: italic;
                border-radius: 3px;
                border-radius: 9999px;

            }
            .unit-price::before{
                // width: 100%;
                content: "Giá chỉ từ";
                font-size: 1em;
                display: flex;
                justify-content: center;
                gap:5px;
                color: rgba(0, 0, 0, 0.5);
                font-style: italic;
                border-radius: 9999px;

            }
            .unit-price::after{
                content: "đồng";
                // width: 100%;
                font-size: 1em;
                display: flex;
                justify-content: center;
                gap:5px;
                color: rgba(0, 0, 0, 0.5);
                font-style: italic;
            }

            .number-of-favorites-box{
                min-width: 70%;
                min-height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap:5px;
                color: rgba(0, 0, 0, 0.5);
                font-style: italic;
                // height: 1em;
            border-radius: 9999px;

                svg{
                    color:rgba(0, 0, 0, 0.5);
                    // height: 1em;
                }

                .number-of-favorites{
                    width: 20%;
                    // height: 1em;
                }

            }

        }
        

    }
    .view-detail{
        display: none;
        border: none;
        background: none;
    }
    @include media-query(lg){
        // width: 12em;
        .product-avt{
            // height: 12em;
        }
        .product-infor{
            .product-name{

            }
        }
    }
    
    @include media-query(md){
        // width: 9em;
        .product-avt{
            // height: 9em;
        }
        .product-infor{
            .product-name{
                
            }
        }
        
    }
    @include media-query(sm){
        // width: 7em;

        .product-avt{
            // height: 7em;
            // width: 50%;
        }
        .product-infor{
            .product-name{
                font-size: 1em;
            }
            .product-detail{
                .unit-price{
                    font-size: .6em;
                }
            }
            .number-of-favorites-box{
                font-size: .6em;
            }
        }
    }
    @include media-query(xs){
        flex-direction: row;
        gap:0;
        background-color: #ececec;
        .product-avt{
            width: 30%;
        }
        .product-infor{
            width: 60%;    
            height: 100%;
            padding: 5px 0px 5px 15px;
            align-items: flex-start;
            .product-name{
                font-size: 1.2em;
                text-align: left;
            }
            .product-detail{
                justify-content: flex-start;
                .unit-price{
                    font-size: 1.2em;
                    text-align: left;
                    justify-content: flex-start;
                    &::before{
                        
                        content: "Chỉ từ";
                    }
                }

                .number-of-favorites-box{
                    justify-content: flex-start;
                    font-size: 1em;
                }
            }
        }
        .view-detail{
            display: flex;
            width: 10%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
    }

}

//// @keyframe ////
.skeleton{
    background-image: linear-gradient(
        90deg,
        #ccc 0px,
        rgb(229 229 229 / 90%) 40px,
        #ccc 80px
    );
    background-size: 300%;
    background-position: 100% 0;
    border-radius: inherit;
    animation: shimmer 2s infinite;
    color:#ccc;
}
@keyframes shimmer {
    to {
      background-position: -100% 0;
    }
}