@import 'reactjs-popup/dist/index.css';
@import '../../styles/config/mixins.scss';

.fullscreen-preview-content{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.145);
    padding: 0.2em 1em;
    .fullscreen-preview-modal{
        // width: 100vw;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        .preview-btns{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            height: 5%;
            .fullscreen-off-btn{
                background:none;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                width: 3em;

                svg{
                    color: white;
                    font-size: 2em;
                }
                &:hover{
                    background-color: white;
                    svg{
                        color: black;
                    }
                }
            }
        }
        .content{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            height: 95%;
            .product-show-fullscreen{
                width: 100%;
                height: 90%;
                display: flex;
                flex-wrap: wrap;
                .product-preview-container{
                    width: 100%;
                    // height: 500px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    // cursor: zoom-in;
                    .show{
                        width: fit-content;
                        // border-radius: 10px;
                        border: non;
                        // width: fit-content;
                        height: 100%;
                    }
                    .video-show{
                        width: 100%;
                        // border-radius: 10px;
                        border: non;
                        // width: fit-content;
                        height: 100%;
                    }
                }
            }
            .product-thumb-bar-fullscreen{
                width: 100%;
                height: 10%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .switch-btn{
                    cursor: pointer;
                    // background-color: $background-color-component;
                    background: none;
                    border: none;
                    svg{
                        font-size: 3em;
                        color: white;
                        &:hover{
                            color: #6E5AA7;
                        }

                        &:active{
                            color: #925aa7;
                        }
                    }
                    
                }
                .product-thumb-bar-content{
                    width: 85%;
                    overflow-x: auto;
                    padding: 0 2px;
                    border-left: solid 2px rgba(174, 174, 174, 0.5);
                    border-right: solid 2px rgba(174, 174, 174, 0.5);

                    .product-thumb-container{
                        width: max-content;
                        display: flex;
                        gap: 3px;
                        .thumb-item-fullscreen{
                            // width: 150px;
                            position: relative;
                            border-radius: 10px;
                            overflow: hidden;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            .thumb{
                                cursor: pointer;
                                max-width: 100%;
                                height: 100%;
                            }
                            .play-video{
                                position: absolute;
                                top: calc(50% - 15px);   
                                left: calc(50% - 15px);
                                display: flex;
                                // width: 150px;
                                // height: 100%;
                                // justify-content: center;
                                // align-items: center;
                                svg{
                                    font-size: 30px;
                                    opacity: 0.7;
                                    color: white;
                                }
                            }
                        }

                        .thumb-item-active{
                            // width: 150px;
                            position: relative;
                            overflow: hidden;
                            height: 50px;
                            border-radius: 10px;
                            border: solid 5px #6E5AA7;
                            .thumb{
                                max-width: 100%;
                                height: 100%;
                            }
                            .play-video{
                                position: absolute;
                                top: calc(50% - 15px);   
                                left: calc(50% - 15px);
                                display: flex;
                                display: flex;
                                svg{
                                    font-size: 30px;
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.edit-n-add-slider-content{
    .edit-slider-modal{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        // overflow: scroll;
        .content{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            // gap:1em;
            .get-img{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap:10px;
                border-bottom: dashed 2px $border-color;
                padding: 0.5em;
                label{
                    color: $title-color;
                    font-weight: 600;
                }
            }
            .product-link{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                border-bottom: dashed 2px $border-color;
                padding: 0.5em;
                .title{
                    p{
                        color: $title-color;
                        font-weight: 600;
                    }
                }
                .product-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: baseline;
                    align-items: baseline;
                    gap:0.5em;
                    .product-box{
                        padding: 0.3em;
                        background-color: $background-color-btn;
                        border: solid 1px $border-color;
                    }
                    .product-box-active{
                        padding: 0.3em;
                        p{
                            color: white;
                        }
                        border: solid 1px $border-color;
                        background-color: $title-color;
                        pointer-events: none;
                    }
                }

            }
            
        }
        .btns{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 1em;
            button{
                padding: 0.3em 0.5em;
            }
        }
    }
}