$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding: 2% 20%;
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 2% 10%;

$border-color:rgba(110, 90, 167, 0.5);

$title-color: #6E5AA7;

.my-profile{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    .title{
        p{
            font-size: $title-font-size;
            font-weight: 600;
            color: $title-color;
        }
    }
    .avt{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap:30px;
        align-items: center;
        .show-avatar-box{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
            }
        }

        .upload-new-avt{
            display: flex;
            .upload-btn{
                display: flex;
                flex-direction: column;
                align-items: baseline;
                gap:10px;
                label{
                    border: solid 2px $title-color;
                    border-radius: 10px;
                    padding: $padding-component;
                    background-color:#dacefa;
                    font-weight: 700;
                    cursor: pointer;
                    &:hover{
                        background-color: $title-color;
                        color: white;
                    }
                }
                p{
                    font-style:italic ;
                    color: rgba(45, 45, 45, 0.566);
                }
            }
            input{
                display: none;
            }
        }
    }
    .detail-infor{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap:10px;
        .line{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .name{
                width: 30%;
                font-size: $subtitle-font-size;
            }
            .infoandbutton{
                width: 70%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                button{
                    border: none;
                    background:none;
                    cursor: pointer;
                    color:#6E5AA7;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        .submit-user{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                width: 20%;
                padding: 0.3em 0;
                border: solid 2px $title-color;
                border-radius: 10px;
                // padding: $padding-component;
                background-color:#dacefa;
                font-weight: 700;
                cursor: pointer;
                &:hover{
                    background-color: $title-color;
                    color: white;
                }
            }
        }
    }
}