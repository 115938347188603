$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding: 2% 20%;
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 2% 10%;

$border-color:rgba(110, 90, 167, 0.5);

$title-color: #6E5AA7;

.main{
    .account-content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: $padding;
        background-color: $background-color-component;
        border-radius: 8px;
        gap:30px;
        .title-account{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap:10px;
            p{
                font-size: 1.5em;
                font-weight: 700;
                color: $title-color;
            }
            hr{
                width: 100%;
                border: 1px solid $border-color;
            }
        }
    }
}