$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;

//this is variable for component
$background-color-component: rgb(247, 247, 247);
$padding-component: 10px;
$border-radius-component: 8px;
$padding-introduce_cpn: 5% 10% 8% 10%;

$border-color:rgba(110, 90, 167, 0.5);
$background-color-btn: rgb(233, 225, 255);

$title-color: #6E5AA7;
$highlight-title-color: #ff6e3d;
$global-font-wieght:600;

