/* Colors */

$primary-color: #484848;        // blue
$secondary-color2: #F9F9F9;
$secondary-color: #F1F1F1;      // gray
$primary-color2:#E7E7E7;
$tertiary-color: #9caa9e;
$logotext: #9C77CB;

$success-color: #28a745;        // green
$danger-color: #dc3545;         // red
$info-color: #17a2b8;           // teal
$warning-color: #ffc107;        // yellow
$light-color: #f8f9fa;          // light gray
$dark-color: #343a40;           // dark gray
$white-color: #ffffff;          // white
$black-color: #000000;          // black
$gray-color: #433f3f;
$light-gray-color: #929090;

$background-color-component: rgb(247, 247, 247);
$title-font-size: 1.2em;
$subtitle-font-size: 1.1em;
$content-font-size: 0.9em;
$border-color:rgba(110, 90, 167, 0.5);
$title-color: #6E5AA7;
$padding: 2% 20%;

/* Fonts */

$font-family: 'Dosis', sans-serif;
$font-size-base: 1rem;

/* Spacing */

$spacing-unit: 1rem;


// Media query mixin
// Define breakpoints
$breakpoints: (
  xs: 576px, // extra small
  sm: 768px, // small
  md: 1024px, // medium
  lg: 1280px // large
);

@mixin media-query($breakpoint) {
    
    @if $breakpoint == xs {
      @media (max-width: map-get($breakpoints, xs)) { @content; }
    }
    @else if $breakpoint == sm {
      @media (max-width: map-get($breakpoints, sm)) { @content; }
    }
    @else if $breakpoint == md {
      @media (max-width: map-get($breakpoints, md)) { @content; }
    }
    @else if $breakpoint == lg {
      @media (max-width: map-get($breakpoints, lg)) { @content; }
    }
  }