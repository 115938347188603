@import "../../styles/config/variables.scss";

// Import your font file
@font-face {
  font-family: 'UTMViceroyJF';
  src: url('../../styles/config/fonts/font-utm-taimienphi.vn/UTM ViceroyJF.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Text Show Slider */
.PoliciesAndPromotions{
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    .LeftArrow{
      height: 16px;
      transform: rotate(180deg);
      margin-left: 20%;
    }
    
    .RightArrow{
      height: 16px;
      margin-right: 20%;
    }

    .TextSlideShow{
      margin: 0 auto;
      overflow: hidden;
      // max-width: 500px;
      .TextSlideshowSlider{
        white-space: nowrap;
        transition: ease 1000ms;
        .TextSlide{
          width: 100%;
          display: inline-block;
          font-size: 40px;
          color: #6E5AA7;
          text-align: center;
          font-family: 'UTMViceroyJF'
          // src: "../../styles/config/fonts/font-utm-taimienphi.vn/UTM\ ViceroyJF.ttf";
        }
      }
    }
  }
  
  
  @include media-query(lg){

  }

  @include media-query(md){
    .PoliciesAndPromotions{
      height: 40px;
      .LeftArrow{
        margin-left: 5%;
      }
      
      .RightArrow{
        margin-right: 5%;
      }
    }
  }

  @include media-query(sm){
    .PoliciesAndPromotions{
      .TextSlideShow{
        .TextSlideshowSlider{
          .TextSlide{

            font-size: 30px;
          }
        }
      }
    }
  }

  @include media-query(xs){
    .PoliciesAndPromotions{
      .TextSlideShow{
        .TextSlideshowSlider{
          .TextSlide{

            font-size: 20px;
          }
        }
      }
    }
  }
  

  
  