@import '../../styles/config/mixins.scss';
@import '../../styles/config/variables.scss';

/* Breadcrumb bar */
.containerpb{
    display: flex;
    justify-content: flex-end;
    background-color: #6E5AA7;
    .page-breadcrumb{
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 1.5rem;
      
      width: 90%;

      // padding-left: 12.1%;
      .website-name{
        // padding-left: 1.5em;
        font-size: 1.1em;
        font-weight: bold;
        color: white;
        
        // width: 25%;
        // justify-content: center;
      }
      .page-name-breadcrumbs{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        
        .right-arrow-breadcrumb{
          margin: 0 1.4em;
          font-size: 1.1em;
          display: inline-block;
        }
    
        .page-name{
          color: white;
          font-size: 1.1em;
        }

      }
    }
    @include media-query(lg)

    {
    @include media-query(md){
      .page-breadcrumb{

        width: 95%;
      }
    }

    }
    @include media-query(sm){
      // width: 94%;

      .page-breadcrumb{
        .page-name-breadcrumbs{
          .page-name{
            font-size: 15px;
          }
          .right-arrow-breadcrumb{
            margin: 0 5px;
          }
        }
      }
    }
    @include media-query(xs){
      .page-breadcrumb{
        .website-name{
          font-size: 0.9em;
        }
        .page-name-breadcrumbs{
          .page-name{
            font-size: 0.8em;
            width: 4em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .right-arrow-breadcrumb{
            font-size: 0.8em;
          }
        }
      }
    }
  }